import React from 'react'
import App from './App'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import './index.css'
import '@mantine/core/styles.css'
import '@fontsource-variable/jetbrains-mono'
import '@fontsource/poppins'
import '@fontsource/satisfy'
import { createTheme } from '@mantine/core'
import { MantineProvider } from '@mantine/core'

const domNode = document.getElementById('root')
const root = createRoot(domNode!)

const theme = createTheme({
  /** Put your mantine theme overrides here */
  fontFamily: 'Poppins, Verdana, sans-serif',
  fontFamilyMonospace: 'JetBrains Mono Variable, monospace',
  headings: {
    fontFamily: 'Poppins',
    sizes: {
      h1: {
        fontSize: '3rem',
      },
    },
  },
  primaryColor: 'teal',
  black: '#333',
})

root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <App />
    </MantineProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
