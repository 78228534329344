import React from 'react'
import {
  ActionIcon,
  Card,
  Center,
  HoverCard,
  Image,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core'
import { faGithub, faLinkedin, faStackOverflow } from '@fortawesome/free-brands-svg-icons'
import { faAt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const stackOverFlowUrl = 'stackoverflow.com/users/250917'
const emailAddress = 'hello@drewspencer.uk'
const linkedInUrl = 'linkedin.com/in/drew-spencer/'
const githubUrl = 'github.com/slugmandrew/'

function emailButton() {
  const label = 'Click to send me an email'
  return (
    <Tooltip withArrow label={label}>
      <ActionIcon
        component='a'
        target={'_blank'}
        href={`mailto:${emailAddress}`}
        aria-label={label}
        variant={'transparent'}
        color={'cyan'}>
        <FontAwesomeIcon size={'lg'} icon={faAt} />
      </ActionIcon>
    </Tooltip>
  )
}

function githubButton() {
  const label = 'Open my GitHub profile in a new tab'
  return (
    <Tooltip withArrow label={label}>
      <ActionIcon
        component='a'
        target={'_blank'}
        href={`https://${githubUrl}`}
        aria-label={label}
        variant={'transparent'}
        color={'black'}>
        <FontAwesomeIcon size={'lg'} icon={faGithub} />
      </ActionIcon>
    </Tooltip>
  )
}

function linkedInButton() {
  const label = 'Open my LinkedIn profile in a new tab'
  return (
    <Tooltip withArrow label={label}>
      <ActionIcon
        component='a'
        target={'_blank'}
        href={`https://${linkedInUrl}`}
        aria-label={label}
        variant={'transparent'}
        color={'blue.5'}>
        <FontAwesomeIcon size={'lg'} icon={faLinkedin} />
      </ActionIcon>
    </Tooltip>
  )
}

function stackOverflowButton() {
  const label = 'Open my StackOverflow profile in a new tab'
  return (
    <Tooltip withArrow label={label}>
      <ActionIcon
        component='a'
        target={'_blank'}
        href={`https://${stackOverFlowUrl}`}
        aria-label={label}
        variant={'transparent'}
        color={'orange.5'}>
        <FontAwesomeIcon size={'lg'} icon={faStackOverflow} />
      </ActionIcon>
    </Tooltip>
  )
}

function App() {
  return (
    <>
      <Stack align={'center'}>
        <HoverCard width={280} shadow='md'>
          <HoverCard.Target>
            <Image
              src={'./images/drew-spell-small-chin.webp'}
              w={300}
              h={300}
              alt='Portrait of Drew Spencer generated using ChatGPT'
            />
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text>It's surprisingly difficult to generate a good image of yourself using A.I!</Text>
          </HoverCard.Dropdown>
        </HoverCard>

        <Title order={1} ff={'Satisfy'}>
          Drew Spencer
        </Title>
        <Title order={3}>Software Engineer</Title>
      </Stack>
      <Space h={'xl'} />
      <Center>
        <Card withBorder radius={'md'} w={400} shadow={'lg'}>
          <Card.Section withBorder px={'sm'} py={'xs'} bg={'blue.0'}>
            <Title order={4}>Contact Details</Title>
          </Card.Section>
          <Card.Section p={'sm'}>
            <Stack>
              <TextInput
                label='Github'
                description='Sometimes I put code here'
                aria-label='Github profile of Drew Spencer'
                value={githubUrl}
                rightSection={githubButton()}
              />
              <TextInput
                label='StackOverflow'
                description="If A.I. hasn't already made this totally obsolete"
                aria-label='StackOverflow profile of Drew Spencer'
                value={stackOverFlowUrl}
                rightSection={stackOverflowButton()}
              />
              <TextInput
                label='LinkedIn'
                description="Because that's where all the action happens"
                aria-label='LinkedIn profile of Drew Spencer'
                value={linkedInUrl}
                rightSection={linkedInButton()}
              />
              <TextInput
                label='Email address'
                description='Get in touch!'
                aria-label='Email address of Drew Spencer'
                value={emailAddress}
                rightSection={emailButton()}
              />
            </Stack>
          </Card.Section>
        </Card>
      </Center>
    </>
  )
}

export default App
